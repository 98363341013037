import React from 'react';
const config = require('../data.json');

const sizes = {
  lg: config.headingSize.lg,
  md: config.headingSize.md,
  sm: config.headingSize.sm,
};
export default function Heading(props) {
  const {
    size = 'md',
    color = config.colors.textSecond,
    weight = null,
    align = 'left',
    style,
  } = props;

  return (
    <div
      style={{
        fontSize: sizes[size],
        color,
        fontWeight: weight || '500',
        textAlign: align,
        margin: '10px 0 ',
        ...style,
      }}>
      {props.children}
    </div>
  );
}

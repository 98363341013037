import GA from './GA';
import Pixel from './FbPixel';
const config = require('../data.json');

const DEBUG = true;

const Catch = (fn) =>
  function (...args) {
    try {
      fn.apply(this, args);
      if (config.debug && DEBUG) {
        console.log('tracking success', fn.name, args);
      }
    } catch (e) {
      if (config.debug && DEBUG) {
        console.error('tracking error:', e);
      }
    }
    return;
  };

const Tracking = {
  addToCart: Catch((_item, userId, event_id) => {
    const item = {
      ..._item,
      quantity:
        typeof (_item.config.quantity_select || 0) === 'object'
          ? _item.config.quantity_select.quantity
          : _item.config.quantity_select,
    };

    GA.addToCart({...item, id: item.name});

    Pixel.addToCart(
      [
        {
          ...item,
          id: item.name,
          quantity: item.quantity,
        },
      ],
      item.price,
      userId,
      event_id,
    );
  }),
  removeFromCart: Catch((cart, index) => {
    let item = cart.items[index];

    GA.removeFromCart(
      {
        ...item,
        id: item.name,
      },
      typeof (item.config.quantity_select || 0) === 'object'
        ? item.config.quantity_select.quantity
        : item.config.quantity_select,
    );
  }),
  purchase: Catch((order, userId, event_id) => {
    const cart = JSON.parse(order.cart);

    GA.purchase(
      cart.items.map((_i) => ({..._i, id: _i.name})),
      {
        ...order,
        tax: cart.calculations.tax || 0,
        shipping: cart.calculations.fee || 0,
      },
      event_id,
    );

    Pixel.purchase(order.amount, cart.items, userId, event_id);
  }),
  addImpression: Catch((product, userId) => {
    GA.addImpression({
      ...product,
      id: product.name,
      name: product.name,
    });

    Pixel.viewContent([product.name], userId);
  }),
  // checkout: Catch((step, cart, params, userId) => {
  //   const items = cart ? cart.items : [];

  //   if (items.length > 0) {
  //     let payment = `${params.payment_type}${
  //       params.payment_subtype ? '_' + params.payment_subtype : ''
  //     }`;

  //     GA.checkout(
  //       items.map((_i) => ({..._i, id: _i.name})),
  //       step + 1,
  //       payment,
  //     );

  //     if (step === 0) {
  //       Pixel.initCheckout(userId);
  //     }
  //   }
  // }),
  checkout: Catch((order, cart, userId, event_id) => {
    GA.checkout(order, cart);

    Pixel.initCheckout(userId, event_id);
  }),
  addPromotionImpression: Catch((promo) => {
    GA.addPromotionImpression(promo);
  }),
  addPromotionImpressionClick: Catch((promo) => {
    GA.addPromotionImpressionClick(promo);
  }),
};

export default Tracking;

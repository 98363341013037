import React, {Fragment} from 'react';
import styled from 'styled-components';
const config = require('../data.json');

export default function OrderBlock(props) {
  const {color} = props;

  return (
    <Fragment>
      <Header color={color}>{props.title}</Header>
      <Wrapper color={color}>{props.children}</Wrapper>
    </Fragment>
  );
}

const Header = styled.p`
  padding: 10px 20px 10px 20px;
  margin: 0;
  font-size: 16px;
  color: ${(props) => (props.color ? 'white' : config.colors.text)};
  background-color: ${(props) => props.color || '#f7f7f7'};
  border: 1px solid ${(props) => props.color || config.colors.border};
  border-radius: 5px 5px 0 0;
  ${(props) => (props.first ? 'margin-top: 0px;' : '')}
`;

const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid ${(props) => props.color || config.colors.border};
  border-top: 0;
  margin-bottom: 20px;
`;

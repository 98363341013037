import styled from 'styled-components';
import {Checkbox} from 'antd';

export default styled(Checkbox)`
  /* TODO: how to change hover border color when checked ? */
`;

// .ant-checkbox-checked :hover {
//   /* it isn't work */
//   border-color: ${Theme.colors.main};
// }
// .ant-checkbox-checked .ant-checkbox-inner {
//   background-color: ${Theme.colors.main};
//   border-color: ${Theme.colors.main};
// }
// .ant-checkbox-wrapper:hover .ant-checkbox-inner,
// .ant-checkbox:hover .ant-checkbox-inner,
// .ant-checkbox-input:focus + .ant-checkbox-inner {
//   border-color: ${Theme.colors.main};
//   outline: 0;
//   box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
// }

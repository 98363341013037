const config = require('../data.json');

const Catch = (fn) =>
  function (...args) {
    try {
      fn.apply(this, args);
      if (!config.debug) {
        console.log('tracking success', fn.name, args);
      }
    } catch (e) {
      if (!config.debug) {
        console.error('tracking error:', e);
      }
    }
    return;
  };

const GA = {
  addToCart: Catch(function (product) {
    window.gtag('event', 'add_to_cart', {
      currency: 'TWD',
      value: product.price,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          price: product.price,
          quantity: product.quantity,
        },
      ],
    });

    // window.ga('ec:addProduct', {
    //   id: product.id,
    //   name: product.name,
    //   price: product.price,
    //   quantity: qty,
    // });
    // window.ga('ec:setAction', 'add');
    // window.ga('send', 'event', 'Click', 'Cart', 'Add ' + product.id);
  }),
  removeFromCart: Catch(function (product) {
    window.gtag('event', 'remove_from_cart', {
      currency: 'TWD',
      value: product.price,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          price: product.price,
          quantity: product.quantity,
        },
      ],
    });
    // window.ga('ec:addProduct', {
    //   id: product.id + '',
    //   name: product.name,
    //   //category: product.label,
    //   //brand: product.store.name,
    //   price: product.price,
    //   quantity: qty,
    // });
    // window.ga('ec:setAction', 'remove');
    // window.ga('send', 'event', 'Click', 'Cart', 'Remove ' + product.id);
  }),
  purchase: Catch(function (items = [], order = {}, event_id) {
    window.gtag('event', 'purchase', {
      currency: 'TWD',
      transaction_id: event_id,
      value: order.amount,
      tax: order.tax,
      shipping: order.shipping,
      coupon: order.coupon,
      items: items.map((item, index) => {
        let q1 = parseInt(item.config.quantity_select),
          q2 = parseInt(item.quantity);
        let q = q1 > q2 ? q1 : q2;

        const price = parseFloat(parseFloat(item.price / q).toFixed(2));

        return {
          item_id: item.name,
          item_name: item.name,
          price,
          quantity: q,
          index,
        };
      }),
    });
    // if (items.length > 0) {
    //   items.forEach((item) => {
    //     window.ga('ec:addProduct', {
    //     });
    //   });
    //   window.ga('ec:setAction', 'purchase', {
    //     id: order.id + '',
    //     affiliation: 'Lixiang',
    //     revenue: order.amount + '',
    //     tax: order.tax,
    //     shipping: order.shipping,
    //   });
    // }
    // window.ga('send', 'event', 'Purchase', items.length + ' items');
    // window.ga(
    //   'send',
    //   'event',
    //   'Purchase',
    //   order.payment_type + ' ' + order.payment_subtype,
    // );
  }),
  addImpression: Catch(function (product) {
    window.gtag('event', 'view_item', {
      currency: 'TWD',
      value: 0,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
        },
      ],
    });
    // window.ga('ec:addImpression', {
    //   id: item.id, // Product ID (string).
    //   name: item.name, // Product name (string).
    //   //category: item.label, // Product category (string).
    //   //brand: item.store.name, // Product brand (string).
    //   //list: "Search Results", // Product list (string).
    //   ...(position !== undefined ? {position} : {}), // Product position (number).
    // });
    // window.ga('send', 'event', from + '-Impression', 'Product', item.name);
    // window.ga('send', 'pageview');
  }),

  checkout: Catch(function (order, cart) {
    const _items = cart ? cart.items : [];
    const items = _items.forEach((item) => {
      let q1 = parseInt(item.config.quantity_select),
        q2 = parseInt(item.quantity);
      let q = q1 > q2 ? q1 : q2;
      const price = parseFloat(parseFloat(item.price / q).toFixed(2));

      return {
        item_id: item.name,
        item_name: item.name,
        price,
        quantity: q,
      };
    });
    window.gtag('event', 'add_payment_info', {
      currency: 'TWD',
      value: order.amount,
      coupon: order.coupon,
      payment_type: order.payment_type + '_' + order.payment_subtype,
      items,
    });

    window.gtag('event', 'begin_checkout', {
      currency: 'TWD',
      value: order.amount,
      coupon: order.coupon,
      items,
    });
  }),
  addPromotionImpression: Catch(function (item) {
    window.gtag('event', 'view_promotion', {
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          creative_name: item.name,
          creative_slot: item.type,
          promotion_id: item.id,
          promotion_name: item.name,
        },
      ],
    });
  }),
  addPromotionImpressionClick: Catch(function (item) {
    window.gtag('event', 'select_promotion', {
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          creative_name: item.name,
          creative_slot: item.type,
          promotion_id: item.id,
          promotion_name: item.name,
        },
      ],
    });
  }),
};

export default GA;

import React from 'react';
import {Space} from 'antd';
import Image from './Image';
const config = require('../data.json');

const icons = {
  facebook: 'social-facebook.svg',
  line: 'social-line.svg',
  shopee: 'social-shopee.svg',
  instagram: 'social-instagram.svg',
};

export default function Links(props) {
  return (
    <Space>
      {Object.keys(config.socialMedia)
        .filter((key) => config.socialMedia[key].showOnFooter)
        .map((key, idx) => (
          <Image
            key={idx}
            linkto={config.socialMedia[key].link}
            src={`/images/${icons[key]}`}
            style={{height: 30, width: 30}}
            alt={'social link - ' + config.socialMedia[key].name}
          />
        ))}
    </Space>
  );
}

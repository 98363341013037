import React from 'react';
import styled from 'styled-components';
import {Input} from 'antd';

export default styled(Input)`
  margin: ${(props) => props.margin || '5px'};
  flex-basis: 220px;
  flex-shrink: 0;
  ${(props) => (props.type === 'short' ? 'flex-basis: 90px; width: 90px;' : '')}
  ${(props) => (props.type === 'long' ? 'flex-basis: 320px;' : '')}
`;

// @media only screen and  (max-width: ${appConfig.breakpoints.sm}px) {
//   flex-basis: ${props =>
//   props.type === "long" ? "260px" : props.type === "short" ? "90px" : ""};
//   flex-shrink: 1;
// }

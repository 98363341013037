const fnCmp = ({key, desc = false}) => (p1, p2) => {
  const k1 = p1[key];
  const k2 = p2[key];

  const factor = desc ? -1 : 1;
  if (k1 === k2) {
    return 0;
  } else if (k1 > k2) {
    return 1 * factor;
  } else {
    return -1 * factor;
  }
};

export default fnCmp;

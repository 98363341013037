import React, {useState} from 'react';
import styled from 'styled-components';
import {ExpandLess, ExpandMore} from 'styled-icons/material';
const config = require('../data.json');

export default function MenuItem(props) {
  const {fontSize} = props;
  const [open, setOpen] = useState(false);
  const {onClick, node} = props;

  if (!node) {
    return null;
  }
  const isRoot = node.name === 'root';
  const hasChildren = node.children && node.children.length > 0;

  return (
    <div>
      {!isRoot && (
        <Item
          fontSize={fontSize}
          onClick={() => {
            if (!hasChildren) {
              return;
            }

            setOpen((value) => !value);
          }}>
          <div
            style={{flex: 1}}
            onClick={(e) => {
              e.stopPropagation();

              onClick(node);
            }}>
            {node.display}
          </div>
          {hasChildren && open && (
            <ExpandLess size={18} color={config.colors.text} />
          )}
          {hasChildren && !open && (
            <ExpandMore size={18} color={config.colors.text} />
          )}
        </Item>
      )}

      {(open || isRoot) && hasChildren && (
        <div style={{paddingLeft: isRoot ? 0 : 15}}>
          {node.children.map((child) => {
            return (
              <MenuItem
                node={child}
                key={child.name}
                onClick={onClick}
                fontSize={fontSize}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
  font-size: ${(props) => props.fontSize || 12}px;

  &:hover {
    color: ${config.colors.main};
  }
`;

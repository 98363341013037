import React from 'react';
import styled from 'styled-components';
import {Link as GastbyLink} from 'gatsby';
const config = require('../data.json');

export default styled(GastbyLink)`
  color: ${config.colors.link};

  &:hover {
    opacity: 0.5;
  }
`;

const LinkButton = styled.button`
  border: 0;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  color: ${config.colors.link};

  &:hover {
    opacity: 0.5;
  }
`;

export {LinkButton};

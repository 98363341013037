import React from 'react';
import {Popover} from 'antd';

export default function WidgetPopover({label, content, labelStyle = {}}) {
  // do not wrapper label with Component, it will not work
  return (
    <Popover content={content}>
      <div
        style={{
          color: '#9b9b9b',
          textDecorationLine: 'underline',
          textDecorationColor: '#9b9b9b',
          cursor: 'default',
          ...labelStyle,
        }}>
        {label}
      </div>
    </Popover>
  );
}

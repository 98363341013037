import React from 'react';
import {AlertCircle} from '@styled-icons/feather';
import Text from './Text';
const config = require('../data.json');

export default function ErrorHint({children}) {
  return (
    <div style={{display: 'flex', alignItems: 'center', margin: '0 10px'}}>
      <AlertCircle size={20} color={config.colors.error} />
      <Text size="xs" color={config.colors.error} style={{marginLeft: 5}}>
        {children}
      </Text>
    </div>
  );
}

import styled from 'styled-components';
import {Radio} from 'antd';
const appConfig = require('../data.json');

export default styled(Radio)`
  display: inline-flex;
  align-items: center;
  font-size: 16px;

  & > .ant-radio {
    margin-bottom: 5px;
  }

  &.ant-radio-wrapper-disabled > .ant-radio-disabled > .ant-radio-inner {
    &::after {
      background-color: ${appConfig.colors.error};
      box-shadow: 0 0 6px 2px ${appConfig.colors.error}99;
    }
  }
`;

/*
.ant-radio-checked .ant-radio-inner {
    color: ${appConfig.colors.main};
    border-color: ${appConfig.colors.main};
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
  .ant-radio-inner::after {
    background-color: ${appConfig.colors.main};
    color: ${appConfig.colors.main};
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${appConfig.colors.main};
    color: ${appConfig.colors.main};
  }
*/

const RadioContent = styled.div`
  margin: 10px 0 10px 20px;
  padding: 10px;
  border-left: 1px solid #ddd;

  & > div {
    font-size: 14px;
  }
`;
const RadioGroup = styled(Radio.Group)`
  width: 100%;
`;

export {RadioContent, RadioGroup};

import React from 'react';
import styled from 'styled-components';
const config = require('../data.json');

const sizes = {
  xl: config.fontSize.xl,
  lg: config.fontSize.lg,
  md: config.fontSize.md,
  sm: config.fontSize.sm,
  xs: config.fontSize.xs,
};
export default function Text(props) {
  const {size = 'md', color = '', weight = null, inline = false, style} = props;

  return (
    <div
      style={{
        fontSize: sizes[size],
        color: color || config.colors.text,
        ...(weight && {fontWeight: weight}),
        ...(inline && {display: 'inline'}),
        ...style,
      }}>
      {props.children}
    </div>
  );
}

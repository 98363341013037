export default async function download(path) {
  let url = `${eval('encodeURI("' + path + '")')}`;
  let filename = path.split('/').slice(-1);

  let resp = await fetch(url);
  let blob = await resp.blob();
  let objUrl = URL.createObjectURL(blob);

  var a = document.createElement('a');
  document.body.appendChild(a);
  a.id = 'rev-download-url';
  a.style = 'display: none';
  a.href = objUrl;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(objUrl);
  document.body.removeChild(a);
}

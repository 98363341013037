import React from 'react';
import styled from 'styled-components';
import Text from './Text';

export default function Tag({text, color, textColor = '#fff', style = {}}) {
  return (
    <Wrapper color={color} style={{...style}}>
      <Text color={textColor} size="xs" weight="600">
        {text}
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 25px;
  background-color: ${(props) => props.color || '#C24C4C'};
  border-radius: 15px;
  padding: 0px 10px;
  white-space: nowrap;
`;

function format(str, fmt = 'YYYY-MM-DD HH:mm') {
  // short solution: new Date(str).toISOString().slice(0 ,16).replace("T", " ")

  if (!str) {
    return '-';
  }

  const date = new Date(str);
  var o = {
    'M+': date.getMonth() + 1, //月份
    'D+': date.getDate(), //日
    'H+': date.getHours(), //小時
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
  };

  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length),
    );
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? o[k]
          : ('00' + o[k]).substr(('' + o[k]).length),
      );
    }
  }

  return fmt;
}

function offsetDay(str = null, x = 0) {
  let date = new Date(str);
  date.setDate(date.getDate() + x);
  return date;
}

function processTimestamp(date) {
  try {
    if (isNaN(date)) {
      throw new Error();
    }
    let value = date;

    // timestamp digit < 13, not millisecond
    if (date.toString().length < 13) {
      value = date * 1000;
    }

    // timezone offset; don't need if use above getHours() method
    // const offset = new Date().getTimezoneOffset() * 60000;
    // return value - offset;

    return value;
  } catch (error) {
    return new Date().getTime();
  }
}

module.exports = {
  format,
  offsetDay,
  processTimestamp,
};

import styled from 'styled-components';
const config = require('../data.json');

export default styled.div`
  display: none;

  &:active {
    opacity: 0.75;
    transition: opacity 0.2s ease-in;
  }

  @media only screen and (max-width: ${(props) =>
      props.breakpoint || config.breakpoints.sm}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 25px;
    bottom: 95px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    cursor: pointer;
    background-color: ${config.colors.main};
    box-shadow: 0px 2px 8px -3px #000;
  }
`;

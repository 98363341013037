import React, {useContext, useEffect} from 'react';
import {Context} from '../AppContext';
import {formatValidator} from '../Utils';
import {ORDER_STATE} from '../dictionary';

const {isNotEmpty} = formatValidator;

export default function useNotification() {
  const app = useContext(Context);
  const profile = app.state.profile;
  useEffect(() => {
    (async () => {
      if (profile) {
        app.actions.clearNotifs();

        // check orders
        let params = {
          //等待付款，不合格，未上傳稿件
          display_state: 'payment_waiting,review_rejected,review_waiting',
          no_page: true,
        };
        let orders = await app.actions.getOrders(params);

        for (let order of orders) {
          app.actions.addNotif({
            key: `pending-order-${order.id}`,
            message: '您有待處理訂單',
            description:
              '您的訂單 ' +
              order.display_id +
              ' ' +
              ORDER_STATE[order.display_state],
            link: `/order/?id=${order.id}`,
          });
        }

        // check logistic
        let logistics = await app.actions.getLogistics({
          logistics_type: 'self_pick',
          logistics_status: 'transit', //可自取
        });
        for (let logistic of logistics.results) {
          app.actions.addNotif({
            key: `selfpick-logistic-${logistic.id}`,
            message: '您有可自取的訂單',
            description:
              '您的訂單 ' + logistic.display_id + ' 請至店內領取包裹',
            link: `/order/?id=${logistic.order}`,
          });
        }

        // check profile complete
        if (
          !isNotEmpty(profile.name) ||
          !isNotEmpty(profile.address) ||
          !isNotEmpty(profile.phone)
        ) {
          app.actions.addNotif({
            key: 'profile-incompelete',
            message: '會員資料未補齊',
            description: '為維護您的權益，請前往會員中心修改會員資料。',
            link: '/profile/',
          });
        }
      }
    })();
  }, [profile, app.actions]);

  return {
    count: app.state.notifs.length,
  };
}

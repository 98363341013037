import React from 'react';
import Text from './Text';
const config = require('../data.json');

export default function Hint({children}) {
  return (
    <Text size="xs" color={config.colors.main} inline>
      {children}
    </Text>
  );
}

const config = require('../data.json');

const Catch = (fn) =>
  function (...args) {
    try {
      fn.apply(this, args);
      if (!config.debug) {
        console.log('tracking success', fn.name, args);
      }
    } catch (e) {
      if (!config.debug) {
        console.error('tracking error:', e);
      }
    }
    return;
  };

const Pixel = {
  addToCart: Catch((contents, value, userId, event_id) => {
    window.fbq(
      'track',
      'AddToCart',
      {
        contents,
        content_type: 'product',
        value,
        currency: 'TWD',
        external_id: userId,
      },
      {eventID: event_id},
    );
  }),
  viewContent: Catch((content_ids, userId) => {
    window.fbq('track', 'ViewContent', {
      content_ids,
      content_type: 'product',
      external_id: userId,
    });
  }),
  initCheckout: Catch((userId, eventID) => {
    window.fbq('track', 'InitiateCheckout', {external_id: userId}, {eventID});
  }),
  purchase: Catch((value, _contents, userId, event_id) => {
    let contents = _contents.map((content) => {
      try {
        let q1 = parseInt(content.config.quantity_select),
          q2 = parseInt(content.quantity);

        return {
          id: content.name,
          quantity: q1 > q2 ? q1 : q2,
        };
      } catch (e) {
        return {
          id: content.name,
          quantity: 1,
        };
      }
    });
    window.fbq(
      'track',
      'Purchase',
      {
        content_type: 'product',
        value,
        contents,
        currency: 'TWD',
        external_id: userId,
      },
      {eventID: event_id},
    );
  }),
  search: Catch((search_string) => {
    window.fbq('track', 'Search', {
      search_string,
    });
  }),
};

export default Pixel;

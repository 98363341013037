import React, {useState, Fragment, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {Text, Divider, Link} from '../../../Widgets';
import {Context} from '../../../AppContext';
const config = require('../../../data.json');

export default function Notification({onClose}) {
  const app = useContext(Context);
  const records = app.state.notifs;

  if (records.length > 0) {
    return (
      <Wrapper>
        {records.map((n, idx) => (
          <Link to={n.link} key={idx} onClick={() => onClose()}>
            {idx !== 0 && <Divider />}
            <Text
              size="sm"
              color={config.colors.main}
              style={{marginBottom: 0}}>
              {n.message}
            </Text>
            <Text size="xs" style={{marginBottom: 10}}>
              {n.description}
            </Text>
          </Link>
        ))}
      </Wrapper>
    );
  }
  return null;
}

const Wrapper = styled.div``;

import React from 'react';
const appConfig = require('../data.json');

function useDimension() {
  const [dimension, setDimension] = React.useState({});
  const [rwd, setRwd] = React.useState('desktop');
  const detectDimension = React.useCallback(() => {
    const nextDimension = {};
    if (typeof window !== undefined) {
      nextDimension.innerWidth = window.innerWidth;
      nextDimension.innerHeight = window.innerHeight;
      nextDimension.outerWidth = window.outerWidth;
      nextDimension.outerHeight = window.outerHeight;
      setDimension(nextDimension);

      if (nextDimension.innerWidth <= appConfig.breakpoints.sm) {
        setRwd('mobile');
      } else if (nextDimension.innerWidth <= appConfig.breakpoints.lg) {
        setRwd('pad');
      } else {
        setRwd('desktop');
      }
    }
  }, []);
  const onResize = React.useCallback(() => {
    detectDimension();
  }, [detectDimension]);

  React.useEffect(() => {
    try {
      detectDimension();
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    } catch (ex) {
      // bypass
    }
  }, [onResize, detectDimension]);

  return {
    dimension,
    rwd,
  };
}

export default useDimension;

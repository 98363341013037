import React from 'react';
import styled from 'styled-components';

function getFullImageUrl(src) {
  return `${src}`;
}

// response image wrapper, ex: 16 : 9 > ratio={9/16}
const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${(props) => props.ratio * 100 || '100'}%;

  & img,
  figure {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;

function ImageContent({src, background, linkto, ratio, style, alt, ...props}) {
  const url = getFullImageUrl(src);
  let extraStyle = {
    ...(props.height && {height: props.height}),
    ...(props.width && {width: props.width}),
  };
  if (!src) {
    return (
      <ImageMissing style={{...style, ...extraStyle}} {...props}>
        ❌
      </ImageMissing>
    );
  }

  if (background) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url("${src}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          ...style,
        }}
        onLoad={(e) => {}}
        onError={(e) => console.warn('ImageBackground onError', e)}
        {...props}
      />
    );
  }

  if (linkto) {
    return (
      <a
        href={(linkto && linkto) || ''}
        target="_blank"
        rel="noreferrer"
        aria-label={alt}>
        <img
          style={style}
          src={url}
          {...props}
          alt={alt || 'link to ' + linkto}
        />
      </a>
    );
  }

  return <img style={style} src={url} {...props} alt={alt || ''} />;
}

export default function Image(props) {
  const {ratio} = props;

  if (ratio) {
    return (
      <ImageWrapper ratio={ratio}>
        <ImageContent {...props} />
      </ImageWrapper>
    );
  }

  return <ImageContent {...props} />;
}

Image.defaultProps = {
  background: false,
};

const ImageMissing = styled.figure`
  margin: 0;
`;

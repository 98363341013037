import React from 'react';
const config = require('../data.json');

const sizes = {
  xl: 20,
  lg: 18,
  md: 16,
  xs: 12,
  sm: 14,
};

export default function Label({
  size = 'sm',
  color = '',
  flex = '0 0 60px',
  children,
}) {
  return (
    <label
      style={{
        marginRight: 10,
        flex,
        fontWeight: '400',
        fontSize: sizes[size],
        color: color || config.colors.textSecond,
      }}>
      {children}
    </label>
  );
}

import React, {useState, Fragment, useEffect, useCallback, useRef} from 'react';
import styled from 'styled-components';
import {Mask} from '../../../Widgets';
import ExpandMenu from './Menu';
import ExpandNotif from './Notification';
import ExpandCategory from './Category';
const config = require('../../../data.json');

export default function Expander({current, name, onClose, ...props}) {
  const [open, setOpen] = useState(false);
  const container = useRef();

  useEffect(() => {
    const bounding = container.current
      ? container.current.getBoundingClientRect()
      : {};
    const {bottom, right, left} = bounding;
    if (bottom < 0 || right < 0 || left < 0) {
      container.current.style = `
      ${bottom < 0 || right < 0 || left < 0 ? 'position: fixed;' : ''}
      ${bottom < 0 ? 'bottom: 0; ' : ''}
      ${right < 0 ? 'right: 0; left: auto;' : ''}
      ${left < 0 ? 'left: 0; right: auto;' : ''}
    `;
    } else {
      container.current.style = '';
    }
  }, [current]);

  useEffect(() => {
    if (current === name) {
      setOpen(true);
    }
  }, [current, name]);

  const close = useCallback(() => {
    setOpen(false);

    let timer = setTimeout(() => {
      onClose();
      clearTimeout(timer);
    }, 200);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <Fragment>
      <Mask visible={open} onClick={() => close()} color="transparent" />
      <Wrapper ref={container} className={`${open ? 'show' : ''} `} {...props}>
        {current
          ? {
              menu: <ExpandMenu onClose={close} />,
              categories: <ExpandCategory onClose={close} />,
              notifications: <ExpandNotif onClose={close} />,
            }[name]
          : null}
      </Wrapper>
    </Fragment>
  );
}

const Wrapper = styled.div`
  padding: 30px 20px;
  background-color: white;
  box-shadow: 0px 2px 8px -4px #555;
  border-top: solid 4px ${config.colors.main};
  position: absolute;
  top: ${config.navbarHeight}px;
  right: 0;
  z-index: 1;

  max-width: calc(100vw - 40px);
  min-width: 200px;
  max-height: calc(100vh - 20px - ${config.navbarHeightMore}px);
  overflow: scroll;

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    position: fixed;
    top: ${config.navbarHeightMore}px;
    right: 20px;
  }

  transform-origin: top center;
  transform: scaleY(0);
  transition: all 0.2s ease-in-out;
  opacity: 0;
  &.show {
    transform: scaleY(1);
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
`;

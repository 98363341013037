import styled from 'styled-components';
const config = require('../data.json');

const TabBar = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  padding: 10px;
  flex: 1;
  text-align: center;
  border-top: ${(props) =>
    props.selected ? '3px solid ' + config.colors.main : '0'};
  border-right: ${(props) => (props.selected ? '0' : '1px solid #eee')};
  background-color: ${(props) => (props.selected ? 'white' : '#ccc')};
  color: ${(props) => (props.selected ? config.colors.main : 'white')};
  cursor: default;
  transition: all 0.2s linear;
  box-sizing: border-box;

  &:last-child {
    border-right: 0;
  }
`;

const TabContent = styled.div`
  min-height: 200px;
  padding: 20px;
`;
const Tabs = {TabBar, TabItem, TabContent};
export default Tabs;

function normalizePath(path) {
  if (path.slice(0, 2) === '//') {
    path = path.slice(1);
  }

  if (path[path.length - 1] !== '/') {
    path += '/';
  }

  return path;
}

export {normalizePath};

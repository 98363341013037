import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react';
import {Context} from '../AppContext';
import {Modal} from 'antd';
import {Close} from 'styled-icons/material';

const initialModal = {
  content: null,
  title: false,
  width: '400px',
};

function ModalContainer(props) {
  const app = useContext(Context);
  const [modal, setModal] = useState(initialModal);

  useEffect(() => {
    if (!app.state.modalContent) {
      setModal(initialModal);
    } else {
      setModal(app.state.modalContent);
    }
  }, [app.state.modalContent]);

  return (
    <Modal
      visible={!!modal.content}
      onCancel={() => app.actions.setModal()}
      title={modal.title}
      footer={false}
      closeIcon={
        <Close
          style={{position: 'absolute', right: 20, top: 20}}
          size={20}
          color="#000"
        />
      }
      zIndex={15}
      width={modal.width}>
      {modal.content}
    </Modal>
  );
}

export default ModalContainer;

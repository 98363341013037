module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"奕果雲端數位股份有限公司","short_name":"HiPrint 印刷/客製電子商務","start_url":"/","background_color":"#284B63","theme_color":"#284B63","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"edc80b304a264ad547df0c2c3be312ea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"393636998280019"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

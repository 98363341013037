function getCategoryFromLocation(category, catTree) {
  function findCat(catName, node) {
    if (node.name === catName) {
      return node;
    }

    for (let child of node.children) {
      const hit = findCat(catName, child);
      if (hit) {
        return hit;
      }
    }

    return null;
  }

  try {
    const catName = category;

    if (!catName) {
      return null;
    }

    return findCat(catName, catTree);
  } catch (ex) {
    return null;
  }
}

export default getCategoryFromLocation;

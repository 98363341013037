import React from 'react';
import Expander from './Expander';
const config = require('../data.json');

export default function FormExpander(props) {
  let {containerStyle, contentStyle, titleStyle, ...extraProps} = props;

  containerStyle = {
    ...containerStyle,
    border: `1px solid ${config.colors.border}`,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    borderRadiusTop: 3,
    borderRadius: '5px 5px 0 0 ',
  };

  contentStyle = {
    ...contentStyle,
    padding: '10px 20px',
  };

  titleStyle = {
    ...titleStyle,
    fontSize: 16,
    color: '#5a5a5a',
  };

  return (
    <Expander
      {...extraProps}
      containerStyle={containerStyle}
      titleStyle={titleStyle}
      contentStyle={contentStyle}>
      {props.children}
    </Expander>
  );
}

import React, {useState, Fragment} from 'react';
import styled from 'styled-components';
import {ExpandLess, ExpandMore} from '@styled-icons/material';

export default function Expander(props) {
  const {
    expand = true,
    title,
    onClick,
    ExpandMoreIcon = () => <ExpandMore size={28} color="#5a5a5a" />,
    ExpandLessIcon = () => <ExpandLess size={28} color="#5a5a5a" />,
    containerStyle = {},
    titleStyle = {},
    contentStyle = {},
    closable = true,
    children,
  } = props;
  const [open, setOpen] = useState(expand);

  let extraProps = {
    ...(onClick && {onClick}),
  };

  if (!children) {
    return (
      <SectionExpand style={containerStyle} {...extraProps}>
        <div style={titleStyle}>{title}</div>
      </SectionExpand>
    );
  }

  return (
    <>
      <SectionExpand
        expand={open}
        onClick={() => setOpen((value) => (closable ? !value : true))}
        style={containerStyle}>
        <div style={{flex: 1, ...titleStyle}}>{title}</div>
        {!closable ? null : open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </SectionExpand>

      {open && (
        <div style={{padding: '10px 0', ...contentStyle}}>{children}</div>
      )}
    </>
  );
}

const SectionExpand = styled.div`
  padding: 10px 0;
  margin-bottom: ${(props) => (props.expand ? '10px' : '20px')};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

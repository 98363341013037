const PRICING_TYPE = [
  'default',
  'single',
  'square',
  'starting',
  'digital',
  'size',
  'common',
  'custom',
];

module.exports = {
  PRICING_TYPE,
};
